<template>
<p>이동중</p>
</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'record_id', 'saved_filter_id', 'label'],
  watch: {
    '$store.state.documents_by_id'() {
      this.load()
    },
    'document_id'() {
      this.load()
    },
  },
  async mounted() {
    this.load()
  },
  methods: {
    load() {
      // console.log('wow', this.document_id, this.$store.state.documents_by_id[this.document_id])
      const document_filter_postfix = this.saved_filter_id ? '-'+this.saved_filter_id : ''
      if (this.$store.state.documents_by_id[this.document_id]) {
        const name = this.$store.state.documents_by_id[this.document_id].name
        // console.log({name})
        this.$router.replace({
          path: `/property/${this.property_id}/customer/${this.document_id}${document_filter_postfix}/${this.$options.filters.encodeText(name)}`,
        })
      }
    }
  }
}
</script>
